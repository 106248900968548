const isMobileRegex = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini|mobile|crios/i;
const isChromeRegex = /chrome/i;

export function checkIsMobile() {
  return typeof window !== "undefined" && isMobileRegex.test(window.navigator.userAgent);
}

export function checkIsChrome() {
  return typeof window !== "undefined" && isChromeRegex.test(window.navigator.userAgent);
}

export function checkIsSafari() {
  return (
    typeof window !== "undefined" &&
    !checkIsChrome() &&
    window.navigator.userAgent.toLowerCase().includes("safari")
  );
}
