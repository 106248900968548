import { DateTime } from "luxon";

import { getLocale } from "./getLocale";
import { NestedKeyOf, localize } from "./sharedTranslations/localize";
import { checkIsSafari } from "./userAgentParsing";

const isSafari = checkIsSafari();

export function toDays(str: string) {
  const [amount, dateType] = str.split(" ").map(el => el.trim());
  return +amount > 1
    ? `${amount} ${localize(`dates.${dateType}s` as NestedKeyOf<object>)}`
    : localize(`dates.${dateType}` as NestedKeyOf<object>);
}

export function toViewDate(date: Date | DateTime, options: Intl.DateTimeFormatOptions = {}) {
  const inputDate: Date = date instanceof DateTime ? date.toJSDate() : date;

  return new Intl.DateTimeFormat(getLocale(), options).format(inputDate);
}

export function toViewLongDate(date: Date | DateTime) {
  try {
    const options = isSafari
      ? { weekday: "long", year: "numeric", month: "long", day: "numeric" }
      : { dateStyle: "full" };
    // dateStyle property not in core typescript def yet (works in all browsers though)
    return toViewDate(date, options as any);
  } catch (error) {
    console.error(error);
    return toViewDate(date); // just in case dateStyle property fails in an old browser
  }
}

export function toViewShortDate(date: Date | DateTime) {
  return toViewDate(date, { day: "numeric", month: "numeric", year: "2-digit" });
}

export function toViewVeryShortDate(date: Date | DateTime) {
  return toViewDate(date, { day: "numeric", month: "numeric" });
}

export function toViewMonthYearDate(date: Date | DateTime) {
  return toViewDate(date, { month: "numeric", year: "2-digit" });
}
